<template>
    <div class="app-header-menu" :class="menuClasses">
        <nuxt-link
            v-for="(item, index) in menuItems"
            :key="'header-menu-item-' + index"
            :class="menuItemClasses"
            :to="item.route"
        >{{ item.text }}
        </nuxt-link>
    </div>
</template>

<script>
import { mapState } from 'pinia';

export default {
    name: 'AppHeaderMenu',
    computed: {
        ...mapState(useHeaderStore, {
            menuItems: 'menuItems',
        }),
        menuClasses () {
            const cls = {};
            cls['hidden md:flex md:gap-x-12 pt-2 pr-14'] = true;
            return cls;
        },
        menuItemClasses () {
            const cls = {};
            cls['text-base font-semibold leading-6 text-white'] = true;
            return cls;
        },
    },
};
</script>
