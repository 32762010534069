
export const useHeaderStore = defineStore('StoreHeader', {
    state: () => ({
        menuItems: [
            {route: '/', text: 'Home'},
            {route: '/prices', text: 'Prices'},
            {route: '/contact', text: 'Contact'},
        ],
    }),
});
