<template>
    <app class="bg-zinc-900">
        <app-header></app-header>
        <app-body>
            <slot />
        </app-body>
        <app-footer></app-footer>
    </app>
</template>

<script>
export default {
    name: 'DefaultLayout',
};
</script>
